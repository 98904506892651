













































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import moment from "moment";
import phone from "phone";
import email from "email-validator";
import { Loader } from "@googlemaps/js-api-loader";
import { PatientAppointment } from "../api/PatientAppointment";
import {
  ServiceConfigurationResponse,
  AppointmentTime,
} from "../api/ServiceConfiguration";
import ConfirmationPage from "./ConfirmationPage.vue";
import MedRiteApiClient from "../api/MedRiteApiClient";
import { get, some, forEach, isEmpty, find, filter } from "lodash";
import "moment-timezone";

const loader = new Loader({
  apiKey: "AIzaSyC5G3YmPpv-SWm7XyCQzC3jKiDjSGBj14g",
  version: "weekly",
  libraries: ["places"],
  region: "us",
});

function isValidDate(dateString: string) {
  // First check for the pattern
  if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString)) return false;

  // Parse the date parts to integers
  const parts = dateString.split("/");
  const day = parseInt(parts[1], 10);
  const month = parseInt(parts[0], 10);
  const year = parseInt(parts[2], 10);

  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month === 0 || month > 12) return false;

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust for leap years
  if (year % 400 === 0 || (year % 100 != 0 && year % 4 === 0))
    monthLength[1] = 29;

  // Check the range of the day
  return day > 0 && day <= monthLength[month - 1];
}

const DATE_FORMAT = "MM/DD/YYYY";

export default Vue.extend({
  name: "AppointmentPage",

  data: () => ({
    genders: ["Male", "Female", "Other"],
    step: 1,
    address1: "",
    address2: "",
    latitude: "",
    longitude: "",
    timeSlot: "",
    patientGender: "",
    serviceType: "",
    visitType: "",
    visitReason: "",
    phoneNumber: "",
    emailAddress: "",
    birthDate: "",
    firstName: "",
    lastName: "",
    notes: "",
    valid: false,
    isReCaptchaVerified: false,
    createAppointmentSuccess: false,
    isCreatingAppointment: false,
    recaptchToken: "",
    errorNotification: false,
    zipCode: "",

    datePicker: {
      menu: false,
      date: moment().format("YYYY-MM-DD"),
    },

    visitReasons: [
      "Flu and fever",
      "Muscular and joint pain",
      "Cough, cold and sinus",
      "Minor injury",
      "Head or ear issue",
      "Genital or urinary issue",
      "Eye issue",
      "Stomach issue",
      "Skin condition",
      "Existing condition",
    ],

    firstNameRules: [
      (v: string) => !!v || "First name is required",
      (v: string) =>
        (v && v.length <= 50) || "Name must be less than 50 characters",
    ],
    lastNameRules: [
      (v: string) => !!v || "Last name is required",
      (v: string) =>
        (v && v.length <= 50) || "Name must be less than 50 characters",
    ],
    phoneRules: [
      (v: string) => !!v || "Phone is required",
      (v: string) =>
        (v && phone(v, "USA", true).length > 0) || "Phone number is invalid.",
    ],
    emailRules: [
      (v: string) => !!v || "Email is required",
      (v: string) => (v && email.validate(v)) || "Email address is invalid.",
    ],
    birthDateRules: [
      (v: string) => !!v || "Date is required",
      (v: string) =>
        (v && isValidDate(v)) ||
        "Date is invalid. Please enter in mm/dd/yyyy format.",
    ],
    configuration: {} as ServiceConfigurationResponse,
    snackbar: {
      open: false,
      message: "",
    },
  }),

  async mounted(): Promise<void> {
    loader
      .load()
      .then((google) => {
        const autocomplete = new google.maps.places.Autocomplete(
          this.$el.querySelector("input") as HTMLInputElement,
          {
            types: ["geocode"],
            componentRestrictions: { country: "us" },
            strictBounds: true,
            fields: ["address_components", "formatted_address", "geometry"],
          }
        );

        autocomplete.addListener("place_changed", () => {
          let place = autocomplete.getPlace();
          this.address1 =
            place.formatted_address === undefined
              ? ""
              : place.formatted_address;
          if (this.address1) {
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
          }

          forEach(
            place.address_components,
            (component: { types: string[]; long_name: string }) => {
              if (component.types.includes("postal_code")) {
                this.zipCode = component.long_name;
              }
            }
          );

          const appointmentStartDate = moment(this.datePicker.date)
            .tz(this.timezone)
            .startOf("date")
            .toISOString();
          const appointmentEndDate = moment(this.datePicker.date)
            .tz(this.timezone)
            .endOf("date")
            .toISOString();

          const client: MedRiteApiClient = MedRiteApiClient.getInstance();
          client
            .getConfigurationByZip(
              this.zipCode,
              appointmentStartDate,
              appointmentEndDate
            )
            .then((response) => {
              this.configuration = response;
              if (!this.isValidZipCode) {
                this.snackbar.message =
                  "Sorry, we do not service your address at the moment.";
                this.snackbar.open = true;
              } else {
                this.snackbar.open = false;
              }
            });
        });
      })
      .catch((e) => {
        console.log(e);
      });
  },

  methods: {
    isAvailable(appointmentTime: AppointmentTime): boolean {
      const startTime = appointmentTime.slot.split("-")[0].trim();
      const startTimeMoment = moment(
        `${this.appointmentDate} ${startTime}`,
        `${DATE_FORMAT} ha`
      ).tz(this.timezone);
      return moment().isBefore(startTimeMoment) && appointmentTime.open;
    },
    selectServiceType(inputValue: string): void {
      this.serviceType = inputValue;
      if (this.serviceType == "antibody") {
        window.open("https://www.welz.com/antibody-treatment");
        return;
      } else if (this.serviceType == "urgentcare") {
        this.visitType = "urgentcare";
      }

      this.step = 3;
    },
    selectVisitType(inputValue: string): void {
      this.visitType = inputValue;
      this.step = 4;
    },
    filterAppointmentTypes(groupId: string) {
      return filter(get(this.configuration, "appointmentTypes"), {
        groupId: groupId,
      });
    },
    formateDate() {
      return moment(this.datePicker.date).format("dddd, MMMM Do YYYY");
    },
    appointmentDates() {
      const dates = [];
      for (let i = 0; i < 7; i++) {
        const d = moment().add(i, "days").tz(this.timezone);
        dates.push({
          label: i == 0 ? "Today" : d.format("ddd"),
          value: d.format(DATE_FORMAT),
        });
      }

      return dates;
    },
    updateConfiguration() {
      const appointmentStartDate = moment(this.datePicker.date)
        .tz(this.timezone)
        .startOf("date")
        .toISOString();
      const appointmentEndDate = moment(this.datePicker.date)
        .tz(this.timezone)
        .endOf("date")
        .toISOString();

      const client: MedRiteApiClient = MedRiteApiClient.getInstance();
      client
        .getConfigurationByZip(
          this.zipCode,
          appointmentStartDate,
          appointmentEndDate
        )
        .then((response) => {
          this.configuration = response;
          this.timeSlot = "";
          if (!this.hasAvaiableAppointments) {
            this.snackbar.message =
              "Sorry, no more appointments are available.";
            this.snackbar.open = true;
          } else {
            this.snackbar.open = false;
          }
        });
    },
  },

  computed: {
    buildPatientAppointment(): PatientAppointment {
      const patientAppointment: PatientAppointment = {
        firstName: this.firstName,
        lastName: this.lastName,
        address1: this.address1,
        address2: this.address2,
        latitude: this.latitude,
        longitude: this.longitude,
        cellPhone: this.phoneNumber,
        email: this.emailAddress,
        dob: this.birthDate,
        sex: this.patientGender,
        appointmentDate: moment(
          `${this.appointmentDate} ${this.timeSlot.split("-")[0].trim()}`,
          `${DATE_FORMAT} ha`
        ).toISOString(),
        appointmentTime: this.timeSlot,
        appointmentType: this.visitType,
        reason: this.visitReason,
        notes: this.notes,
      };

      return patientAppointment;
    },
    dailyTimeSlots(): AppointmentTime[] {
      const scheduleId = get(
        find(get(this.configuration, "appointmentTypes"), {
          id: this.visitType,
        }),
        "scheduleId"
      );
      const appointmentSchedule = find(
        get(this.configuration, "appointmentSchedules"),
        { id: scheduleId }
      );
      return moment(this.appointmentDate, DATE_FORMAT)
        .tz(this.timezone)
        .isoWeekday() > 5
        ? get(appointmentSchedule, "weekend", [])
        : get(appointmentSchedule, "weekdays", []);
    },
    hasAvaiableAppointments(): boolean {
      return some(this.dailyTimeSlots, this.isAvailable);
    },
    isValidZipCode(): boolean {
      return !isEmpty(get(this.configuration, "appointmentSchedules"));
    },
    timezone() {
      return this.configuration.timezone || "US/Eastern";
    },
    appointmentDate() {
      return moment(this.datePicker.date).format(DATE_FORMAT);
    },
  },

  watch: {
    step: function (currentStep) {
      if (currentStep == 3) {
        this.visitReason = "";
      } else if (currentStep == 5) {
        this.timeSlot = "";
        if (!this.hasAvaiableAppointments) {
          this.snackbar.message = "Sorry, no more appointments are available.";
          this.snackbar.open = true;
        } else {
          this.snackbar.open = false;
        }
      }
    },
    "datePicker.date": function (appointmentDate) {
      if (moment(appointmentDate) < moment()) {
        this.datePicker.date = moment().format("YYYY-MM-DD");
      }

      this.updateConfiguration();
    },
  },

  components: { ConfirmationPage },
});
