import { AxiosResponse } from "axios";
import {
  createPatientAppointment,
  PatientAppointment,
  PatientAppointmentResponse,
} from "./PatientAppointment";
import {
  getConfigurationByZip,
  ServiceConfigurationResponse,
} from "./ServiceConfiguration";

export default class MedRiteApiClient {
  private static instance: MedRiteApiClient;

  private constructor() {
    //prevent direct instantiation.
  }

  public static getInstance(): MedRiteApiClient {
    if (!MedRiteApiClient.instance) {
      MedRiteApiClient.instance = new MedRiteApiClient();
    }

    return MedRiteApiClient.instance;
  }

  public async createPatientAppointment(
    patientAppointment: PatientAppointment,
    recaptchaToken: string
  ): Promise<PatientAppointmentResponse> {
    try {
      const patientResponse: AxiosResponse<PatientAppointmentResponse> =
        await createPatientAppointment(patientAppointment, recaptchaToken);
      const { status } = patientResponse;
      if (status === 201) {
        const { data } = patientResponse;
        return data;
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async getConfigurationByZip(
    zip: string,
    appointmentStartDate: string,
    appointmentEndDate: string
  ): Promise<ServiceConfigurationResponse> {
    try {
      const response: AxiosResponse<ServiceConfigurationResponse> =
        await getConfigurationByZip(
          zip,
          appointmentStartDate,
          appointmentEndDate
        );
      const { status } = response;
      if (status === 200) {
        const { data } = response;
        return data;
      } else {
        return Promise.reject();
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
