import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import AppointmentPage from "../components/AppointmentPage.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/appointment",
    name: "AppointmentPage",
    component: AppointmentPage,
  },
  {
    path: "/thank-you",
    name: "ThankYouPage",
    component: () =>
      import(
        /* webpackChunkName: "options" */ "../components/ThankYouPage.vue"
      ),
  },
  {
    path: "*",
    redirect: "/appointment",
  },
];

const router: VueRouter = new VueRouter({
  routes,
});

export default router;
