import { AxiosPromise } from "axios";
import request from "./HttpClient";

export interface AppointmentTime {
  slot: string;
  open: boolean;
  capacity: number;
}

export interface AppointmentSchedule {
  id: string;
  weekdays: AppointmentTime[];
  weekend: AppointmentTime[];
}

export interface AppointmentType {
  id: string;
  name: string;
  description: string;
  scheduleId: string;
  groupId: string;
}

export interface AppointmentGroup {
  id: string;
  name: string;
}

export interface ServiceConfigurationResponse {
  timezone: string;
  appointmentTypes: AppointmentType[];
  appointmentGroups: AppointmentGroup[];
  appointmentSchedules: AppointmentSchedule[];
}

export function getConfigurationByZip(
  zip: string,
  appointmentStartDate: string,
  appointmentEndDate: string
): AxiosPromise<ServiceConfigurationResponse> {
  return request({
    url: "/services/configurations/options",
    method: "get",
    params: {
      zip,
      appointmentStartDate,
      appointmentEndDate,
    },
  });
}
