


































































































































































import Vue from "vue";
import VueRecaptcha from "vue-recaptcha";
import MedRiteApiClient from "../api/MedRiteApiClient";
import {
  PatientAppointment,
  PatientAppointmentResponse,
} from "../api/PatientAppointment";
import { ServiceConfigurationResponse } from "../api/ServiceConfiguration";
import { get, find } from "lodash";
import moment from "moment";

export default Vue.extend({
  name: "ConfirmationPage",

  data: () => ({
    recaptchToken: "",
    isReCaptchaVerified: false,
    createAppointmentSuccess: false,
    isCreatingAppointment: false,
    snackbar: {
      open: false,
      message: "",
    },
  }),

  props: {
    patientAppointment: {
      type: Object as () => PatientAppointment,
    },
    isValid: {
      type: Boolean,
    },
    configuration: {
      type: Object as () => ServiceConfigurationResponse,
    },
  },
  components: { VueRecaptcha },

  methods: {
    stepBack() {
      this.$emit("step-back");
    },

    formatDate(isoDate: string) {
      return moment(isoDate).format("dddd, MM/DD");
    },

    onVerify(token: string) {
      this.recaptchToken = token;
      this.isReCaptchaVerified = true;
    },

    onCaptchaExpired() {
      this.isReCaptchaVerified = false;
      this.recaptchToken = "";
    },

    getServiceName(appointmentType: string) {
      return get(
        find(this.configuration.appointmentTypes, { id: appointmentType }),
        "name",
        ""
      );
    },

    async confirmAppointment() {
      const medriteClient: MedRiteApiClient = MedRiteApiClient.getInstance();

      this.isCreatingAppointment = true;
      this.snackbar.open = false;

      if (
        this.isValid &&
        this.isReCaptchaVerified &&
        this.recaptchToken != ""
      ) {
        try {
          const createdPatient: PatientAppointmentResponse =
            await medriteClient.createPatientAppointment(
              this.patientAppointment,
              this.recaptchToken
            );
          if (createdPatient !== undefined) {
            this.$router.push("/thank-you");
          }
        } catch (error) {
          this.snackbar.message = get(
            error,
            "response.data.message",
            "Failed to schedule appointment. Pleae try again."
          );
          this.snackbar.open = true;
        } finally {
          this.isCreatingAppointment = false;
        }
      }
    },
  },
});
