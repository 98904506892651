import axios from "axios";
import { AxiosInstance } from "axios";

const service: AxiosInstance = axios.create({
  baseURL: "https://api.nycovidtest.com/api/v1",
  timeout: 30000, // milliseconds
});

service.interceptors.request.use(
  (config) => {
    config.headers["Cache-Control"] = "no-cache";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
