import { AxiosPromise } from "axios";
import request from "./HttpClient";
import { CUSTOM_HEADERS } from "./CustomHeaders";

export interface PatientAppointment {
  firstName: string;
  lastName: string;
  dob: string;
  sex: string;
  cellPhone: string;
  email: string;
  address1: string;
  address2: string;
  latitude: string;
  longitude: string;
  appointmentDate: string;
  appointmentTime: string;
  appointmentType: string;
  reason: string;
  notes: string;
}

export interface PatientAppointmentResponse {
  id: string;
}

export function createPatientAppointment(
  data: PatientAppointment,
  recaptchaToken: string
): AxiosPromise<PatientAppointmentResponse> {
  return request({
    url: "/patient-appointment",
    method: "post",
    data,
    headers: {
      [CUSTOM_HEADERS.RECAPTCHA_TOKEN_HEADER]: recaptchaToken,
    },
  });
}
